// import Image from 'next/image'

// import FeatImage01 from '@/public/images/features-03-image-01.png'
// import FeatImage02 from '@/public/images/features-03-image-02.png'
// import FeatImage03 from '@/public/images/features-03-image-03.png'
import { useEffect } from 'react'

export default function Calendar() {
    useEffect(() => {
        const head = document.querySelector('head');
        if (head){
            const script = document.createElement('script');
            script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
            head.appendChild(script);
        
            return () => {
              // Cleanup if necessary
              if (head.contains(script)) {
                head.removeChild(script);
              }
            };
        }
      }, []);
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4">Reach goals that matter</div>
            <h1 className="h2 mb-4 text-purple-600">Book your Free Marketing Assessment</h1>
            <p className="text-xl text-gray-400">Gain valuable insights tailored to your business. Our experts will analyze your current strategies, identify growth opportunities, and provide personalized recommendations to maximize your marketing efforts.</p>
          </div>

          <div id="schedule_form">
                <div
                className="calendly-inline-widget"
                data-url="https://calendly.com/i-singh423/30min"
                style={{ minWidth: '320px', height: '580px' }}
                />
            </div>

        </div>
      </div>
    </section>
  )
}
