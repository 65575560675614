import React, { useState } from 'react';

const ContactForm = () => {
  const [responseMessage, setResponseMessage] = useState('');
  const [formData, setFormData] = useState({
    fullName: '',
    companyName: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      setResponseMessage('Please enter a valid email address.');
      return;
    }

    if (formData.message.length < 10) {
      setResponseMessage('Please enter a message with at least 10 characters.');
      return;
    }

    try {
      const response = await fetch('https://leadcatalyst-d37b8482cb92.herokuapp.com/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: formData, type: 'contact' }),
      });

      if (response.ok) {
        setResponseMessage('Your query has been sent successfully.');
        setFormData({
          fullName: '',
          companyName: '',
          email: '',
          message: '',
        });
      } else {
        setResponseMessage('Failed to send your query. Please try again later.');
      }
    } catch (error) {
      setResponseMessage('An error occurred. Please try again later.');
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <section className="max-w-6xl mx-auto px-4 sm:px-6">
      <div className="py-12 md:py-10 border-t border-gray-800">
        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
          <h2 className="h2 mb-4 text-purple-600">Get in Touch</h2>
          <p className="text-xl text-gray-400">We exist to make entrepreneurship easier.</p>
        </div>

        <div className="w-full max-w-lg mx-auto">
          <form onSubmit={handleSubmit} className="w-full bg-gray-700 p-8 rounded-lg shadow-lg">
            <div className="mb-4">
              <label className="block text-gray-300 text-sm font-medium mb-2" htmlFor="fullName">Full Name <span className="text-red-600">*</span></label>
              <input id="fullName" type="text" className="form-input w-full text-gray-900" placeholder="First and last name" value={formData.fullName} onChange={handleChange} required />
            </div>
            <div className="mb-4">
              <label className="block text-gray-300 text-sm font-medium mb-2" htmlFor="companyName">Company Name <span className="text-red-600">*</span></label>
              <input id="companyName" type="text" className="form-input w-full text-gray-900" placeholder="Your company or app name" value={formData.companyName} onChange={handleChange} required />
            </div>
            <div className="mb-4">
              <label className="block text-gray-300 text-sm font-medium mb-2" htmlFor="email">Email <span className="text-red-600">*</span></label>
              <input id="email" type="email" className="form-input w-full text-gray-900" placeholder="you@yourcompany.com" value={formData.email} onChange={handleChange} required />
            </div>
            <div className="mb-6">
              <label className="block text-gray-300 text-sm font-medium mb-2" htmlFor="message">Message <span className="text-red-600">*</span></label>
              <textarea id="message" className="form-input w-full text-gray-900" placeholder="Message (at least 10 characters)" value={formData.message} onChange={handleChange} required></textarea>
            </div>
            <div className="text-sm text-gray-500 mb-6 text-center">
              I agree to be contacted by LeadCatalyst Agency about my query.
            </div>
            <div>
              <button type="submit" className="btn text-white bg-purple-600 hover:bg-purple-700 w-full">Send query</button>
            </div>
            <div className="text-gray-400 text-center mt-6">
                {responseMessage}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
