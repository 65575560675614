import { useEffect } from "react";
import Hero from "./components/Hero";
import Calendar from "./components/calendar";
import Features from "./components/features";
import PageIllustration from "./components/page-illustration";
import Testimonials from "./components/testimonials";
import Footer from "./components/ui/footer";
import Header from "./components/ui/header";
import AOS from 'aos';
import 'aos/dist/aos.css';
import ContactForm from "./components/contactus";
import Newsletter from "./components/newsletter";

export default function App() {
  useEffect(() => {
    AOS.init()
  }, [])
  return (
    <div className="font-inter antialiased bg-gray-900 text-gray-200 tracking-tight">
      <div className="flex flex-col min-h-screen overflow-hidden">
        <Header/>
        <Hero />
        <div id='features-section'>
          <Features />
        </div>
        <div id="calendar-section">
          <Calendar />
        </div>
        <Testimonials />
        <div className="grow">
          <PageIllustration />
        </div>
        <div id="contactus">
          <ContactForm />
        </div>
        <Newsletter />
        <Footer />

      </div>
    </div>
  )
}


export const metadata = {
  title: 'LeadCatalyst - Home',
  description: 'Helping companies generate High Quality Leads in 30 Days with No Upfront Payment',
}